import { motion } from "framer-motion";
import { Box, Typography } from "@mui/material";
import { LinkedIn, Twitter } from "@mui/icons-material";
import LogoHeader from "./components/LogoHeader";
import PageLinkButton from "./components/PageLinkButton";
import IconLinkButton from "./components/IconLinkButton";
import FadingDiv from "./components/FadingDiv";

export default function Home() {
  return (
    <motion.main
      id="home"
      className="flex flex-col items-center"
      initial={{ opacity: 0 }}
      animate={{ opacity: 100 }}
      exit={{ opacity: 0 }}
    >
      <Box
        sx={{
          height: "100vh",
          maxWidth: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <LogoHeader />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "50%",
          }}
        >
          <FadingDiv
            duration={2}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Typography
              id="heading"
              variant="h5"
              color="white"
              textAlign={"center"}
              margin={5}
            >
              Making markets fast, liquid, and efficient
            </Typography>
          </FadingDiv>
          <FadingDiv
            delay={1}
            duration={2}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Typography
              id="body"
              variant="body2"
              color="rgb(167 169 172)"
              marginX={2}
              textAlign={"center"}
              sx={{ width: 750, maxWidth: "90%", wordWrap: "break-word" }}
            >
              We are a high-frequency trading firm constantly redefining
              algorithmic trading, We specialize in market neutral strategies.
              That means we thrive regardless of whether the bulls or bears are
              winning. With offices in San Francisco and London we run our
              strategies 24/7 around the globe.
            </Typography>
          </FadingDiv>
          <FadingDiv
            id={"actions"}
            className={"flex w-6/12 justify-evenly pt-2"}
            delay={2}
            duration={2}
          >
            <PageLinkButton to={"/careers"} text={"Careers"} />
            <PageLinkButton
              to={"mailto:inquiries@dexterity.capital"}
              text={"Contact"}
            />
          </FadingDiv>
        </Box>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            position: "absolute",
            bottom: 25,
            justifyContent: "center",
            alignContent: "center",
            marginX: "auto",
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "25%",
              justifyContent: "space-evenly",
            }}
          >
            <IconLinkButton
              to={"https://twitter.com/DexterityCap"}
              icon={<Twitter className="transition-all hover:text-white" />}
            />
            <IconLinkButton
              to={"https://www.linkedin.com/company/dexterity-capital"}
              icon={<LinkedIn className="transition-all hover:text-white" />}
            />
          </Box>
        </Box>
      </Box>
    </motion.main>
  );
}
